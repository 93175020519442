











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JesusMovie extends Vue {
  getSource() {
    if (this.$i18n.locale === 'en') {
      return 'https://api.arclight.org/videoPlayerUrl?refId=1_529-jf-0-0&apiSessionId=61bd0b29be02b0.58782966&player=bc.vanilla6&dtm=0&playerStyle=default';
    }
    if (this.$i18n.locale === 'de') {
      return 'https://api.arclight.org/videoPlayerUrl?refId=1_1106-jf-0-0&apiSessionId=61d1563d549177.61776912&player=bc.vanilla6&dtm=0&playerStyle=default';
    }

    if (this.$i18n.locale === 'ar') {
      return 'https://api.arclight.org/videoPlayerUrl?refId=1_21061-jf-0-0&apiSessionId=61d2362243d803.70120207&player=bc.vanilla6&dtm=0&playerStyle=default';
    }

    if (this.$i18n.locale === 'cz') {
      return 'https://api.arclight.org/videoPlayerUrl?refId=1_4432-jf-0-0&apiSessionId=61d1bc0851bf90.04332813&player=bc.vanilla6&dtm=0&playerStyle=default';
    }
    return 'https://api.arclight.org/videoPlayerUrl?refId=1_529-jf-0-0&apiSessionId=61bd0b29be02b0.58782966&player=bc.vanilla6&dtm=0&playerStyle=default';
  }
  source = this.getSource();
}
