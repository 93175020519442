export default {
  header: {
    about: 'الصفحة الرئيسية',
    music: 'موسيقى',
    lyrics: 'كلمات الاغنية',
    info: 'معلومات',
    contact: 'اتصل',
    links: 'الروابط',
  },

  cover: {
    name: 'ابناء ابراهيم',
    buttons: {
      listen: 'استمع الآن',
      more: 'تعرف أكثر',
      lyrics: 'قراءة كلمات',
      buy: 'اشتري الآن',
    },
  },

  about: {
    listener: 'عزيزي المستمع',
    about: 'عن ماذا يدور الموضوع؟',
    text1: `الألبوم الذي توشك أن تسمعه تم إنشاؤه بواسطة مجموعة موسيقية تسمى سمفونية الامم. بدأت رحلة انشاء هذا الالبوم في فبراير ٢٠١٧، .حين سافرنا الى بغداد لزيارة مخيمات اللاجئين المحلية في المنطقة`,
    text2:
      'شرح لنا أصدقاؤنا هناك كيف أن المجتمعات الناطقة باللغة العربية في جميع أنحاء العالم بحاجة إلى شيء من شأنه أن يثير الشعور بالأمل والسلام ، ودعونا للتعاون معهم لإنشاء مشروع موسيقي مع مطرب محلي.',
    text3:
      'على مدار عامين ، شارك موسيقيون من دول مختلفة في كتابة هذا الألبوم وتسجيله ، حيث جلب كل منهم نكهة فريدة من ثقافتهم الخاصة. الآن ، مع اقتراب نهاية عام 2020 ، يسعدنا أن نقدم لكم هذا المنتج النهائي. نتمنى أن نستخدم هذا الألبوم كنقطة اتصال بين الثقافات الشرقية والغربية ، وعلى وجه الخصوص إلقاء الضوء على جمال التراث العراقي. نأمل أن يكون ما أنشأناه معًا بين الدول بذرة زرعت من أجل أمل جديد وفرح جديد وحياة جديدة في الشرق الأوسط وما وراءه.',
    hope:
      'نأمل <br /> أن يكون ما أنشأناه معًا بين الدول بذرة زرعت من أجل أمل جديد وفرح جديد وحياة جديدة في الشرق الأوسط وما وراءه.',
  },

  footer: {
    cookie:
      'يستخدم هذا الموقع ملفات تعريف الارتباط لضمان حصولك على أفضل تجربة على موقعنا.',
    cookieButton: 'فهمتك!',
  },

  contact: {
    name: 'اسم',
    email: 'بريد إلكتروني',
    emailPlaceholder: 'يمكنك إضافة بريدك الإلكتروني حتى نتمكن من الرد عليك',
    message: 'رسالة',
    messagePlaceholder: 'ماذا تريد ان تقول لنا؟',
    submit: 'إرسال',
    header: 'نريد أن نسمع منك',
    footer: 'شكرا لزيارتكم لنا',
    info:
      'اذا كان لديك سؤال أو اقتراح لا تتردد بالاتصال بنا. سوف نجيبك بأقرب وقت ممكن',
    failed: 'فشل إرسال الرسالة. حاول مرة اخرى',
    successful: 'أشكركم على الاتصال بنا',
  },

  links: {
    text: `اذا اعجبتك موسيقانا واردت ان تعرف المزيد عن الله فأنت في المكان المناسب
    هنا بعض المصادر والروابط التي ستساعدك لتتعرف على الله الآب.
    اضغط عليها واتصل بنا رجاءً اذا كانت لديك المزيد من الاسئلة
    يسعدنا التواصل معك
    `,
  },
};
