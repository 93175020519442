


































import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: ['up'],
})
export default class LanguagePicker extends Vue {
  langs = ['en', 'de', 'ar', 'cz'];
  selectedLang = this.$cookies.get('locale') ?? this.$i18n.locale;
  dropdown = false;

  mounted() {
    document.addEventListener('click', this.close);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.close);
  }

  changeLanguage(lang: string) {
    this.dropdown = false;
    this.selectedLang = lang;
    this.$root.$i18n.locale = lang;
    this.$cookies.set('locale', lang);
  }
  close(ev: MouseEvent) {
    const target = ev.target;
    if (
      target instanceof Node &&
      !this.$el.contains(target) &&
      window.innerWidth > 1000
    ) {
      this.dropdown = false;
    }
  }
}
