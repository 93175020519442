
























































































import { Component, Vue } from 'vue-property-decorator';
import AudioPlayer from '@/components/AudioPlayer.vue';
import Song from '@/components/Song.vue';
import { songs } from '@/content';
import { AudioPlayerRef } from '../mixins/audioPlayerRef';

@Component({
  components: {
    AudioPlayer,
    Song,
  },
})
export default class Music extends Vue {
  private songs = songs;

  private locale = this.capitalizeFirstLetter(this.$i18n.locale);
  private lyricsIndex = 0;
  private songIndex = 0;
  private songTitle = this.songs[this.songIndex]['title' + this.locale];
  private format = ['mp3'];
  host = process.env.VUE_APP_AUDIO_SERVER ?? 'http://localhost:8000';
  url = this.host + '/song/';
  private currentSource = [this.url + this.songIndex + '/full'];
  private lyrics = this.songs[this.lyricsIndex]['text' + this.locale].replace(
    /(?:\r\n|\r|\n)/g,
    '<br>'
  );

  nextSong() {
    const wasAlreadyPlaying = this.songs[this.songIndex].isPlaying;
    this.songs[this.songIndex].isPlaying = false;
    this.songIndex++;

    if (this.songIndex >= 11) {
      this.songIndex = 0;
    }
    this.setNewSong(wasAlreadyPlaying);
  }

  private refreshSource() {
    this.currentSource = [this.url + this.songIndex + '/full'];
  }

  previousSong() {
    const wasAlreadyPlaying = this.songs[this.songIndex].isPlaying;
    this.songs[this.songIndex].isPlaying = false;
    this.songIndex--;
    if (this.songIndex < 0) {
      this.songIndex = 0;
    }
    this.setNewSong(wasAlreadyPlaying);
  }

  private setNewSong(wasAlreadyPlaying: boolean | undefined) {
    this.refreshSource();
    this.songs[this.songIndex].isPlaying = wasAlreadyPlaying;
    this.showLyrics(this.songIndex + 1);
  }

  playSong(track: number) {
    if (this.songIndex === track - 1) {
      (this.$refs.audioPlayer as AudioPlayerRef).clickOnPlay();
      return;
    }
    this.songs[this.songIndex].isPlaying = false;
    this.songIndex = track - 1;
    this.songTitle = this.songs[this.songIndex].titleEn;
    this.setNewSong(true);
    (this.$refs.audioPlayer as AudioPlayerRef).clickOnPlay();
  }
  pauseSong() {
    this.songs[this.songIndex].isPlaying = false;
    (this.$refs.audioPlayer as AudioPlayerRef).clickOnPause();
  }
  playerToggled(playing: boolean) {
    const song = this.songs[this.songIndex];
    song.isPlaying = playing;
    this.$set(this.songs, this.songIndex, song);
  }
  showLyrics(track: number) {
    this.lyricsIndex = track - 1;
    this.locale = this.capitalizeFirstLetter(this.$i18n.locale);
    const song = this.songs[this.lyricsIndex];
    this.songTitle = song['title' + this.locale] ?? song.titleEn;
    this.lyrics = this.getLyrics();
  }

  private getLyrics(): any {
    if (!this.songs) return '';
    const song = this.songs[this.lyricsIndex];
    return (song['text' + this.locale] ?? song.textEn).replace(
      /(?:\r\n|\r|\n)/g,
      '<br>'
    );
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
