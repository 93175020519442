















































































import { Component, Vue } from 'vue-property-decorator';
import LanguagePicker from './LanguagePicker.vue';

@Component({
  components: {
    LanguagePicker,
  },
})
export default class Header extends Vue {
  private isActive = false;
  scrollPosition = 0;

  updateScroll() {
    this.scrollPosition = window.scrollY;
  }
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  }
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
