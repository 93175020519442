





















import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueHowler from '../mixins/vue-howler';

@Component({})
export default class AudioPlayer extends mixins(VueHowler) {
  public clickOnPlay() {
    this.play();
    this.playing = true;
    this.$emit('play-toggle', true);
  }
  public clickOnPause() {
    this.pause();
    this.playing = false;
    this.$emit('play-toggle', false);
  }
}
