



























































































import { Component, Vue } from 'vue-property-decorator';
import CookieLaw from 'vue-cookie-law';
import LanguagePicker from './LanguagePicker.vue';

@Component({ components: { CookieLaw, LanguagePicker } })
export default class Footer extends Vue {
  private date: number = new Date().getFullYear();
}
