



















































import { Component, Vue } from 'vue-property-decorator';
import AlbumButtons from '@/components/AlbumButtons.vue';
import DearListener from '@/components/DearListener.vue';
import DearListener2 from '@/components/DearListener2.vue';
import WhatAbout from '@/components/WhatAbout.vue';
import Sons from '@/components/Sons.vue';

@Component({
  components: {
    AlbumButtons,
    DearListener,
    DearListener2,
    WhatAbout,
    Sons,
  },
})
export default class Home extends Vue {}
