
























import { SongInfo } from '@/content/songInterface';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SongText extends Vue {
  @Prop() private song!: SongInfo;
  @Prop() private language!: 'en' | 'ar';
  @Prop() private active!: boolean;

  @Watch('active', { immediate: true, deep: true })
  onActiveChanged(val: boolean) {
    this.isActive = val;
  }
  private isActive = this.active;

  closeModal() {
    this.isActive = false;
    this.$emit('update:active', false);
  }
  getText() {
    const rawText =
      this.language === 'en' ? this.song.textEn : this.song.textAr;

    return rawText.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  getTitle() {
    return this.language === 'en' ? this.song.titleEn : this.song.titleAr;
  }
}
