import { song1 } from './song1';
import { song2 } from './song2';
import { song3 } from './song3';
import { song4 } from './song4';
import { song5 } from './song5';
import { song6 } from './song6';
import { song7 } from './song7';
import { song8 } from './song8';
import { song9 } from './song9';
import { song10 } from './song10';
import { song11 } from './song11';
import { SongInfo } from './songInterface';

export const songs: SongInfo[] = [
  song1,
  song2,
  song3,
  song4,
  song5,
  song6,
  song7,
  song8,
  song9,
  song10,
  song11,
];
