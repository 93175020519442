




































import { SongInfo } from '@/content/songInterface';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SongText from '@/components/SongText.vue';

@Component({
  components: {
    SongText,
  },
})
export default class Song extends Vue {
  @Prop() private song!: SongInfo;
  private isActive = false;
  private language: 'en' | 'ar' = 'en';
  showLyrics(language: 'en' | 'ar') {
    this.language = language;
    this.isActive = true;
  }
}
